a:link, a:visited {
  color: #77f;
}

a:visited {
  text-decoration-color: #00f;
}

a:link {
  text-decoration-color: #fff;
}

a:link:not(:hover), a:visited:not(:hover) {
  text-decoration: none;
}

/*# sourceMappingURL=index.0e457f2c.css.map */
